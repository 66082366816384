<template>
  <b-container class="page-container">
    <Breadcrumbs currentpage="Aanvraag succesvol" />
    <b-row>
      <b-col xs="12" md="8">
        <h1 class="pb-3">Bevestiging van je accountaanvraag</h1>
        <h4 class="pb-3">Bedankt voor je aanvraag!</h4>
        <p>
          Wij hebben je gegevens en de details van je aanvraag goed ontvangen en
          gaan hier nu mee aan de slag. We controleren via de website van de
          gemeente/stad of via de gemeente zelf. Hou er rekening mee dat dit
          enkele werkdagen in beslag kan nemen.
        </p>
        <p>
          Heb je vragen over je account of de toewijzingsprocedure? Neem dan
          contact op met
          <a href="mailto:uitleendienst@vlaamsbrabant.be">
            uitleendienst@vlaamsbrabant.be
          </a>
        </p>
        <h4>In tussentijd al eens snuisteren door ons aanbod?</h4>
        <router-link class="btn btn-primary mt-3" :to="{ name: 'index' }"
          >Bekijk onze catalogus</router-link
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Breadcrumbs from '@/components/header/BreadCrumbs'
export default {
  components: { Breadcrumbs }
}
</script>

<style scoped lang="scss">
.page-container {
  h4 {
    font-size: 35px;
  }
  p {
    font-size: 18px;
  }
}
</style>
